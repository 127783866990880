import { useState, useRef, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import useAuth from "../hooks/useAuth";
import { useNavigate, useLocation } from "react-router-dom";
import { directionService } from "../services/directionService";
import { Link } from "react-router-dom";
import "./Login.css";

import signInGoogle from "../images/SignInGoogle.png";

import { config } from "../Environment";
import { WSContext } from "../App";
import { Label } from "./ui/label";
import { Button } from "./ui/button";
import { Input } from "./ui/input";

import PresListContext from "../context/PresListProvider";

function Login() {
  const { setAuth } = useAuth();

  const { setPrescriptions } = useContext(PresListContext);

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from.pathname || "/activities";

  const emailRef = useRef();

  const { t } = useTranslation();

  const { socket } = useContext(WSContext);

  useEffect(() => {
    emailRef.current.focus();
    let directionUnsubscribe = directionService
      .getDirectionChange()
      .subscribe(() => {
        setDirection(document.body.dir);
      });

    return () => {
      directionUnsubscribe.unsubscribe();
    };
  }, []);

  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");

  const [direction, setDirection] = useState(document.body.dir);

  useEffect(() => {
    setErrMsg("");
  }, [email, pwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email || !pwd) {
      emailRef.current.focus();
      return setErrMsg(t("msg_error_email_and_password_required"));
    }

    let response = await fetch(config.API_BASE + "/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: pwd,
      }),
      credentials: "include",
    });
    if (!response.ok) {
      setErrMsg(t("msg_error_email_or_password_not_correct"));
      emailRef.current.focus();
    } else {
      setEmail("");
      setPwd("");
      let data = await response.json();

      let accessToken = data?.accessToken;
      localStorage.setItem("email", email);
      localStorage.setItem("accessToken", accessToken);
      setAuth({ email, accessToken });

      // send login message
      socket.send(
        JSON.stringify({
          command: "login",
          payload: {
            email,
          },
        })
      );

      setPrescriptions(null); // delete possible public prescriptions after login

      if (sessionStorage.getItem("draftprescription")) {
        navigate("/design");
      } else {
        navigate(from, { replace: true });
      }
    }
  };

  const changeEmail = (e) => {
    setEmail(e.target.value);
  };

  const changePassword = (e) => {
    setPwd(e.target.value);
  };

  return (
    <div className="h-full w-full flex items-start lg:items-center justify-start py-12">
      <div className="mx-auto grid w-[350px] gap-6">
        <div className="grid gap-2 text-center">
          <h1 className="text-3xl font-bold">{t("login")}</h1>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="grid gap-4">
            <div className="grid gap-2">
              <Label htmlFor="email">{t("email_address")}</Label>
              <Input
                id="email"
                type="email"
                placeholder="m@example.com"
                onChange={changeEmail}
                ref={emailRef}
                value={email}
                required
              />
            </div>
            <div className="grid gap-2">
              <div className="flex items-center">
                <Label htmlFor="password">{t("password")}</Label>
                <Link
                  to="/forgotpassword"
                  className="ms-auto inline-block text-sm underline"
                >
                  {t("forgot_your_password")}
                </Link>
              </div>
              <Input
                id="password"
                type="password"
                onChange={changePassword}
                value={pwd}
                required
              />
            </div>
            {errMsg ? <div className="error">{errMsg}</div> : ""}
            <Button type="submit" className="w-full">
              {t("login")}
            </Button>
          </div>
        </form>
        <div className="mt-3 text-center text-sm">
          <div>
            {/* <img
              onClick={() =>
                window.open(`${config.API_BASE}/auth/google`, "_self")
              }
              style={{ margin: "0 auto", cursor: "pointer" }}
              src={signInGoogle}
              alt="Google login"
            /> */}
            <button
              className={`googlelogin${direction}`}
              onClick={() => {
                if (location.state) {
                  sessionStorage.setItem(
                    "locationstate",
                    JSON.stringify(location.state)
                  );
                }
                window.open(`${config.API_BASE}/auth/google`, "_self");
              }}
            >
              <img src={signInGoogle} alt="Google login" title="Google login" />
              {t("login_with_google")}
            </button>
          </div>
          {t("dont_have_an_account")}
          <Link
            to="/register"
            state={
              location.state
                ? { from: { pathname: location.state.from.pathname } }
                : null
            }
            className="underline ms-2"
          >
            {t("sign_up")}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Login;
