import React, { createContext } from "react";
import { common, createLowlight } from "lowlight";
import { useEditor } from "@tiptap/react";
import { Color } from "@tiptap/extension-color";
import TextStyle from "@tiptap/extension-text-style";
import ListItem from "@tiptap/extension-list-item";
import StarterKit from "@tiptap/starter-kit";
import TextAlign from "@tiptap/extension-text-align";
import Image from "@tiptap/extension-image";
import Underline from "@tiptap/extension-underline";
import CodeBlockLowlight from "@tiptap/extension-code-block-lowlight";
import Highlight from "@tiptap/extension-highlight";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import Gapcursor from "@tiptap/extension-gapcursor";
import Table from "@tiptap/extension-table";
import TableRow from "@tiptap/extension-table-row";
import TableHeader from "@tiptap/extension-table-header";
import TableCell from "@tiptap/extension-table-cell";
import TextDirection from "tiptap-text-direction";

const CustomTextAlign = TextAlign.extend({
  addOptions() {
    return {
      types: [],
      alignments: ["left", "center", "right", "justify"],
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          textAlign: {
            parseHTML: (element) =>
              element.style.textAlign || this.options.defaultAlignment,
            renderHTML: (attributes) => {
              const alignment = attributes.textAlign;
              return { style: `text-align: ${alignment}` };
            },
          },
        },
      },
    ];
  },
});

const TiptapContext = createContext({
  editor: null,
});

function TiptapProvider({ children }) {
  const lowlight = createLowlight(common);

  const editor = useEditor({
    extensions: [
      Color.configure({ types: [TextStyle.name, ListItem.name] }),
      TextStyle.configure({ types: [ListItem.name] }),
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false,
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false,
        },
      }),
      CustomTextAlign.configure({
        types: ["heading", "paragraph"],
      }),
      Image.configure({
        inline: true,
        allowBase64: true,
      }),
      Underline,
      CodeBlockLowlight.configure({
        lowlight,
      }),
      Highlight,
      Document,
      Paragraph,
      Text,
      Gapcursor,
      Table.configure({
        resizable: true,
      }),
      TableRow,
      TableHeader,
      TableCell,
      TextDirection.configure({
        types: ["paragraph", "heading"],
      }),
    ],
    editorProps: {
      attributes: {
        class: "m-2 focus:outline-none",
        spellcheck: "false",
      },
    },
  });

  return (
    <TiptapContext.Provider
      value={{
        editor,
      }}
    >
      {children}
    </TiptapContext.Provider>
  );
}

export { TiptapProvider, TiptapContext };
