import { useEffect, useRef, useState } from "react";
import { useContext } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { WSContext } from "../App";
import useAuth from "../hooks/useAuth";
import { useEffectOnce } from "../hooks/useEffectOnce";
import { distributionService } from "../services/distributionService";
import LoggerContext from "../context/LoggerProvider";
import cookies from "js-cookie";

import "./Chat.css";

const Chat = ({
  otherEmail,
  processId,
  otherIsExpert,
  autochat,
  onStateChange,
}) => {
  const { auth } = useAuth();
  const { Logger } = useContext(LoggerContext);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const [chatRunning, setChatRunning] = useState(
    autochat ? "chatting" : auth.email === otherEmail ? "nochat" : "idle"
  );
  const [userlist, setUserList] = useState([]);

  // Comes in globally now via Context
  // const [roomId, setRoomId] = useState(0);

  const [chatMessages, setChatMessages] = useState([]);
  const [enteredMessage, setEnteredMessage] = useState("");

  //const roomRef = useRef();
  const textboxRef = useRef(null);
  const chatdiv = useRef(null);

  const { socket, roomId, setRoomId, roomRef } = useContext(WSContext);

  useEffect(() => {
    onStateChange(chatRunning);
  }, [chatRunning]);

  useEffect(() => {
    if (chatRunning === "chatting" && chatdiv.current)
      chatdiv.current.scrollTop = chatdiv.current?.scrollHeight;
  }, [chatMessages]);

  useEffectOnce(() => {
    let messageUnsubscribe = distributionService
      .getMessage()
      .subscribe((msg) => {
        Logger.info()("Chat incoming message: ");
        Logger.info()(msg);

        switch (msg.command) {
          case "initialmessages":
            Logger.info()("INITIALMESSAGES received in Chat.jsx");
            setChatMessages(msg.payload);
            break;
          case "refusechat":
            setChatRunning("idle");
            socket.send(
              JSON.stringify({
                command: "changeuserstate",
                payload: "online",
              })
            );
            break;
          case "acceptchat":
            setChatRunning("chatting");
            Logger.debug()(
              "CHAT: ROOM ID SET TO",
              msg.payload.roomId,
              "while receiving acceptchat message"
            );
            setRoomId(msg.payload.roomId);

            setChatMessages((prevChatMessages) => {
              let newChatMessage = {
                user: "",
                message: t("msg_user_entered_chat"),
              };
              return [...prevChatMessages, newChatMessage];
            });
            break;
          case "leaveroom":
            Logger.info()("Message LEAVEROOM has been received!");
            setChatMessages((prevChatMessages) => {
              let newChatMessage = {
                user: "",
                message: t("msg_user_left_chat"),
              };
              return [...prevChatMessages, newChatMessage];
            });
            alert(t("msg_user_left_chat"));
            setChatRunning("idle");
            Logger.debug()(
              "CHAT: ROOM ID SET TO 0 while receiving leaveroom message"
            );
            setRoomId(0);
            setEnteredMessage("");
            setChatMessages([]);
            socket.send(
              JSON.stringify({
                command: "changeuserstate",
                payload: "online",
              })
            );
            navigate("/activities");
            break;
          case "chatmessage":
            Logger.info()(`Received chatmessage to me (${auth.email})`);
            Logger.info()(msg.payload);
            setChatMessages((prevChatMessages) => {
              let newChatMessage = {
                user: msg.payload.fromUser,
                message: msg.payload.message,
                timestamp: msg.payload.timestamp,
              };
              return [...prevChatMessages, newChatMessage];
            });
            break;
          default:
            break;
        }
      });

    let userlistUnsubscribe = distributionService
      .getUserlist()
      .subscribe((users) => {
        // set room id if autochat
        if (autochat) {
          let locroomId = users.find(
            (user) => user.email === auth.email
          ).roomId;
          Logger.debug()("CHAT: ROOM ID SET TO", locroomId, "in subscriber");
          setRoomId(locroomId);
          setChatMessages((prevChatMessages) => {
            let newChatMessage = {
              user: "",
              message: t("msg_user_entered_chat"),
            };
            return [...prevChatMessages, newChatMessage];
          });

          // trigger receipt of initialmessages
          socket.send(
            JSON.stringify({
              command: "autochatentered",
              payload: { roomId: locroomId },
            })
          );
        }
        setUserList(users);
      });

    return () => {
      // cleanup subscriptions, set online state
      Logger.info()("Chat cleanup", roomRef.current);
      messageUnsubscribe.unsubscribe();
      userlistUnsubscribe.unsubscribe();

      leaveRoom();
    };
  }, []);

  useEffect(() => {
    roomRef.current = roomId;
  }, [roomId]);

  const leaveRoom = () => {
    Logger.info()("Leave room?", roomRef.current);
    if (roomRef.current) {
      Logger.info()("Message LEAVEROOM has been sent!");
      socket.send(
        JSON.stringify({
          command: "leaveroom",
          payload: undefined,
        })
      );
      socket.send(
        JSON.stringify({
          command: "changeuserstate",
          payload: "online",
        })
      );
      setChatRunning("idle");
      setChatMessages([]);
      setEnteredMessage("");
      Logger.debug()("CHAT: ROOM ID SET TO 0 in leaveRoom()");
      setRoomId(0);
      navigate("/activities");
    }
  };

  const requestChat = (email) => {
    socket.send(
      JSON.stringify({
        command: "requestchat",
        payload: { email, processId, fromExpert: !otherIsExpert },
      })
    );

    setChatRunning("waiting");

    // send ws message to change user state to busy
    socket.send(
      JSON.stringify({
        command: "changeuserstate",
        payload: "busy",
      })
    );
  };

  const sendMessage = () => {
    if (chatRunning === "chatting") {
      socket.send(
        JSON.stringify({
          command: "chatmessage",
          payload: {
            roomId,
            fromUser: auth.email,
            message: enteredMessage,
          },
        })
      );
    }
    setEnteredMessage("");
    textboxRef.current.focus();
  };

  const checkForEnterKey = (e) => {
    if (e.key === "Enter") {
      sendMessage();
    }
  };

  const showLocaleTime = (ts) => {
    return new Date(ts).toLocaleTimeString(
      cookies.get("i18next").split("-")[0] || "en"
    );
  };

  const renderChatMessages = () => {
    let currentDate = "1970-01-01";
    let resultingMessages = [];
    for (let msg of chatMessages) {
      let messageDate = msg.timestamp
        ? new Date(msg.timestamp).toLocaleDateString(
            cookies.get("i18next").split("-")[0] || "en"
          )
        : currentDate;
      if (messageDate !== currentDate) {
        let newDateMessage = {
          user: "",
          message: messageDate,
          timestamp: "",
        };
        resultingMessages.push(newDateMessage);
        currentDate = messageDate;
      }
      resultingMessages.push(msg);
    }
    return (
      <>
        {resultingMessages.map((msg, idx) => (
          <div
            key={idx}
            className={
              msg.user === auth.email
                ? "meintext"
                : msg.user === ""
                  ? "systemnachricht"
                  : "anderertext"
            }
          >
            {msg.message}
            {msg.timestamp ? (
              <p className="smalltime">{showLocaleTime(msg.timestamp)}</p>
            ) : (
              ""
            )}
          </div>
        ))}
      </>
    );
  };

  return (
    <div>
      {chatRunning === "nochat" ? (
        ""
      ) : userlist
          .filter((user) => user.state === "online" || user.roomId === roomId)
          .map((user) => user.email)
          .indexOf(otherEmail) !== -1 ? (
        chatRunning === "chatting" ? (
          <div className="chatarea">
            {otherEmail}
            <div className="chatausgabe no-scrollbar" ref={chatdiv}>
              {renderChatMessages()}
            </div>
            <p>
              <input
                ref={textboxRef}
                type="text"
                placeholder={t("enter_chat_message")}
                className="textbox"
                value={enteredMessage}
                onChange={(e) => setEnteredMessage(e.target.value)}
                onKeyPress={(e) => checkForEnterKey(e)}
              />
              &nbsp;
              <button
                style={{
                  border: "1px solid blue",
                  borderRadius: "3px",
                  padding: "8px",
                }}
                type="button"
                onClick={() => sendMessage()}
              >
                {t("send_message")}
              </button>
            </p>
            <p>
              <span className="spanbutton" onClick={() => leaveRoom()}>
                {t("leave_chat")}
              </span>
            </p>
          </div>
        ) : chatRunning === "idle" ? (
          <>
            <p>
              {otherIsExpert
                ? t("expert_online", { otherEmail })
                : t("user_online", { otherEmail })}
            </p>
            <p style={{ marginTop: "20px" }}>
              <Trans i18nKey="ask_for_chat">
                Do you want to{" "}
                <span
                  className="spanbutton"
                  onClick={() => requestChat(otherEmail)}
                >
                  start a chat session?
                </span>
              </Trans>
            </p>
          </>
        ) : (
          <span>
            {otherIsExpert ? t("waiting_for_expert") : t("waiting_for_user")}
          </span>
        )
      ) : (
        <span>
          {otherIsExpert
            ? t("expert_offline", { otherEmail })
            : t("user_offline", { otherEmail })}
        </span>
      )}
    </div>
  );
};

export default Chat;
