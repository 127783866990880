import { useEffect, useState, useContext, useMemo } from "react";
import { useEffectOnce } from "../hooks/useEffectOnce";
import { useNavigate, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import useRetryFetch from "../hooks/useRetryFetch";
import { useTranslation } from "react-i18next";
import { config } from "../Environment";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "./ui/tabs";
import "./PrescriptionList.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  solid /* regular */,
} from "@fortawesome/fontawesome-svg-core/import.macro"; // <-- import styles to be used
import { distributionService } from "../services/distributionService";
import cookies from "js-cookie";

import LoggerContext from "../context/LoggerProvider";
import PresListContext from "../context/PresListProvider";
import { WSContext } from "../App";

// for modal Preview!
import Preview from "./Preview";

import { Skeleton } from "./ui/skeleton";
import { Button } from "./ui/button";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
  DropdownMenuItem,
  DropdownMenuContent,
} from "./ui/dropdown-menu";

import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarSub,
  MenubarSubContent,
  MenubarSubTrigger,
  MenubarTrigger,
} from "./ui/menubar";

import { Dialog, DialogContent, DialogHeader, DialogTitle } from "./ui/dialog";
import { toast } from "sonner";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./ui/table";

const ActivitiesList = () => {
  const { auth } = useAuth();

  const {
    refreshLists,
    prescriptions,
    publicPrescriptions,
    processes,
    expertProcesses,
  } = useContext(PresListContext);

  const location = useLocation();
  const navigate = useNavigate();
  // background shows that PREVIEW should be opened as modal on top of current page!
  let background =
    location.state &&
    location.state.background &&
    location.state.background.pathname !== "/"
      ? location.state.background
      : null;

  const { t } = useTranslation();

  const [currentPrescription, setCurrentPrescription] = useState("");
  const [currentProcess, setCurrentProcess] = useState("");
  const [allUsers, setAllUsers] = useState([]);

  const [reference, setReference] = useState(null);

  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 800px)").matches
  );

  const [filterown, setFilterown] = useState("ALL");
  const [filterexpert, setFilterexpert] = useState("ALL");

  const [showTerminate, setShowTerminate] = useState(false);
  const [showArchive, setShowArchive] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showRename, setShowRename] = useState(false);

  const [presNameNew, setPresNameNew] = useState("");

  const validPrescriptions = useMemo(() => {
    return prescriptions?.filter((prescription) => !!prescription.valid);
  }, [prescriptions]);

  const filteredProcesses = useMemo(() => {
    return filterown === "ALL"
      ? processes
      : processes?.filter((process) => process.status === filterown);
  }, [processes, filterown]);

  const filteredExpertProcesses = useMemo(() => {
    return filterexpert === "ALL"
      ? expertProcesses
      : expertProcesses?.filter((process) => process.status === filterexpert);
  }, [expertProcesses, filterexpert]);

  const { Logger } = useContext(LoggerContext);

  const { listRefresh, setListRefresh } = useContext(WSContext);

  const retryFetch = useRetryFetch();

  useEffectOnce(() => {
    const handler = (e) => setIsMobile(e.matches);
    window.matchMedia("(max-width: 800px)").addEventListener("change", handler);

    sessionStorage.removeItem("prescriptionID");
    sessionStorage.removeItem("prescriptionHistoryID");
    sessionStorage.removeItem("runProcessID");

    refreshLists();

    // subscribe to incoming messages
    let messageUnsubscribe = distributionService
      .getMessage()
      .subscribe((msg) => {
        Logger.debug()("PrescriptionList incoming message: ");
        Logger.debug()(msg);

        switch (msg.command) {
        }
      });

    (async function () {
      let response = await retryFetch(`${config.API_BASE}/api/users`);
      let users = await response.json();

      users.sort((a, b) => {
        if (a.email < b.email) return -1;
        if (a.email > b.email) return 1;
        return 0;
      });

      setAllUsers(users);
    })();

    return () => {
      messageUnsubscribe.unsubscribe();
    };
  }, []);

  const selectPrescription = (idx, startempty) => {
    if (!startempty) {
      sessionStorage.setItem("prescriptionID", prescriptions[idx]._id);
    } else {
      sessionStorage.removeItem("prescriptionID");
    }
    sessionStorage.removeItem("draftprescription");
    navigate("/design");
  };

  const sharePrescription = async (idx) => {
    let response = await retryFetch(
      `${config.API_BASE}/api/deeplinks/${prescriptions[idx]._id}`,
      {
        method: "POST",
      }
    );
    let newDeeplink = await response.json();
    if (window.isSecureContext) {
      navigator.clipboard.writeText(
        `${window.location.origin}/preview?id=${newDeeplink.id}`
      );
      toast.success(t("msg_link_copied_to_clipboard"));
    } else {
      toast.error(t("msg_error_clipboard_insecure"), {
        className: "!bg-red-500 !text-white",
      });
    }
    refreshLists();
  };

  const exportPrescription = async (idx) => {
    let response = await retryFetch(
      `${config.API_BASE}/api/prescriptions/download/${prescriptions[idx]._id}`
    );
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `ClaryNext_${prescriptions[idx]._id}_${Date.now()}.json`
    );
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const runPrescription = async (prescriptions, idx) => {
    // create a new process and start it
    let newResponse = await retryFetch(
      config.API_BASE + `/api/processes/${prescriptions[idx]._id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          email: auth.email,
        }),
      }
    );
    let result = await newResponse.json();

    sessionStorage.setItem("prescriptionID", prescriptions[idx]._id);
    sessionStorage.setItem("processID", result.processId);
    sessionStorage.removeItem("nextActivity");

    // isMobile ? navigate("/preview") : window.open("/preview", "_blank");
    navigate("/activities", { state: { background: location } });
  };

  const runOtherPrescription = async (idx) => {
    // create a new process and start it
    let newResponse = await retryFetch(
      config.API_BASE + `/api/processes/${publicPrescriptions[idx]._id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          email: auth.email,
        }),
      }
    );
    let result = await newResponse.json();

    sessionStorage.setItem("prescriptionID", publicPrescriptions[idx]._id);
    sessionStorage.setItem("processID", result.processId);
    sessionStorage.removeItem("nextActivity");

    // isMobile ? navigate("/preview") : window.open("/preview", "_blank");
    navigate("/activities", { state: { background: location } });
  };

  const continueProcess = async (idx) => {
    // retrieve process and continue it, look for pendingInnerProcess!

    let processToContinue = filteredProcesses[idx]._id;
    if (filteredProcesses[idx].pendingInnerProcess) {
      Logger.debug()("PENDING INNER PROCESS detected, switching to this one!");
      processToContinue = filteredProcesses[idx].pendingInnerProcess;
    }
    let newResponse = await retryFetch(
      config.API_BASE + `/api/processes/getnextactivity/${processToContinue}`
    );
    let result = await newResponse.json();
    Logger.debug()(result);

    if (result.nextActivity) {
      sessionStorage.setItem("prescriptionID", result.fiPrescription);
      sessionStorage.setItem("processID", processToContinue);
      sessionStorage.setItem("nextActivity", result.nextActivity);
      // isMobile ? navigate("/preview") : window.open("/preview", "_blank");
      navigate("/activities", { state: { background: location } });
    } else {
      refreshLists();
    }
  };

  const terminateProcess = async (idx) => {
    setCurrentProcess(filteredProcesses[idx]._id);
    setShowTerminate(true);
  };

  const showUserLog = (idx) => {
    Logger.debug()("UserLog should be shown for " + filteredProcesses[idx]._id);
    let stateToPass = {
      processId: filteredProcesses[idx]._id,
      presName: filteredProcesses[idx].prescriptionName,
      presHistoryId: filteredProcesses[idx].fiPrescriptionHistory,
      expertEmail: filteredProcesses[idx].fiExpert?.email,
      autochat: false,
    };
    navigate("/review", { state: stateToPass });
  };

  const showFullLog = (idx) => {
    Logger.debug()(
      "FullLog should be shown for " + filteredExpertProcesses[idx]._id
    );
    let stateToPass = {
      processId: filteredExpertProcesses[idx]._id,
      presName: filteredExpertProcesses[idx].prescriptionName,
      presHistoryId: filteredExpertProcesses[idx].fiPrescriptionHistory,
      userEmail: filteredExpertProcesses[idx].fiInitiator?.email,
      autochat: false,
    };
    navigate("/review", { state: stateToPass });
  };

  const deletePrescription = (idx) => {
    setCurrentPrescription(prescriptions[idx]._id);
    if (prescriptions[idx].versions > 0) {
      setShowArchive(true);
    } else {
      setShowDelete(true);
    }
  };

  const renamePrescription = (idx) => {
    setReference(prescriptions[idx]._id);
    setPresNameNew(prescriptions[idx].name);
    setShowRename(true);
  };

  const showLocale = (ts) => {
    return new Date(ts).toLocaleString(
      cookies.get("i18next").split("-")[0] || "en"
    );
  };

  const viewPrescription = (idx, isMine) => {
    if (isMine)
      sessionStorage.setItem("prescriptionID", prescriptions[idx]._id);
    else sessionStorage.setItem("prescriptionID", publicPrescriptions[idx]._id);

    sessionStorage.removeItem("draftprescription");
    navigate("/readonly");
  };

  const copyPrescription = async (idx) => {
    let presId = publicPrescriptions[idx]._id;
    await retryFetch(
      `${config.API_BASE}/api/prescriptions/public/copy/${presId}`,
      {
        method: "POST",
        credentials: "include",
      }
    );

    toast.success(t("msg_activity_copy_successful"));

    refreshLists();
  };

  const selectUser = async (processId, email) => {
    Logger.debug()("User should be assigned: ", processId, email);

    await retryFetch(
      `${config.API_BASE}/api/processes/setuser/${processId}/${email}`,
      {
        method: "PUT",
        credentials: "include",
      }
    );

    refreshLists();
  };

  const copyPrescriptionAsExample = async (idx) => {
    try {
      let response = await retryFetch(
        `${config.API_BASE}/api/prescriptions/copy/${prescriptions[idx]._id}`,
        {
          method: "POST",
          credentials: "include",
        }
      );

      if (!response.ok)
        throw new Error(response.status + ": " + response.statusText);

      toast.success(t("msg_activity_copy_successful"));

      refreshLists();
    } catch (e) {
      toast.error(t("msg_error_activity_copy"), {
        className: "!bg-red-500 !text-white",
      });
    }
  };

  const handleImportPrescription = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async function (e) {
        const content = e.target.result;
        try {
          const importData = JSON.parse(content);

          let newResponse = await retryFetch(
            config.API_BASE + "/api/prescriptions/import",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              credentials: "include",
              body: JSON.stringify(importData),
            }
          );

          let data = await newResponse.json();

          if (!data.name) throw new Error("");
          let newName = data.name;

          toast.success("Prescription was successfully imported as " + newName);

          setListRefresh(true);
        } catch (error) {
          toast.error("Error importing prescription", {
            className: "!bg-red-500 !text-white",
          });

          console.error("Error parsing JSON:", error);
        }
      };
      reader.readAsText(file);
    }
  };

  useEffect(() => {
    if (listRefresh) {
      refreshLists();
      setListRefresh(false);
    }
  }, [listRefresh]);

  return (
    <div>
      {isMobile ? (
        <Table className="preslist">
          <TableHeader>
            <TableRow>
              <TableHead>{t("name")}</TableHead>
              <TableHead>{t("actions")}</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {!!validPrescriptions
              ? validPrescriptions.map((prescription, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{prescription.name}</TableCell>
                      <TableCell className="text-center">
                        <FontAwesomeIcon
                          title={t("run")}
                          icon={solid("play")}
                          onClick={() =>
                            runPrescription(validPrescriptions, index)
                          }
                        />
                      </TableCell>
                    </TableRow>
                  );
                })
              : Array(Math.floor(Math.random() * 10 + 5))
                  .join(".")
                  .split(".")
                  .map((_, arrIdx) => {
                    return (
                      <TableRow key={arrIdx}>
                        <TableCell>
                          <Skeleton className="h-[20px] w-[260px] rounded-xl bg-gray-200" />
                        </TableCell>
                        <TableCell>
                          <Skeleton className="h-[20px] w-[40px] rounded-xl bg-gray-200" />
                        </TableCell>
                      </TableRow>
                    );
                  })}
          </TableBody>
        </Table>
      ) : (
        <div className="preslist centercontainer">
          <Tabs defaultValue="activities">
            <TabsList>
              <TabsTrigger
                className="data-[state=active]:bg-primary data-[state=active]:text-white data-[state=active]:shadow-sm"
                value="activities"
              >
                {t("my_activities")}
              </TabsTrigger>
              <TabsTrigger
                className="data-[state=active]:bg-primary data-[state=active]:text-white data-[state=active]:shadow-sm"
                value="public"
                disabled={!auth.email}
              >
                {t("other_activities")}
              </TabsTrigger>
              <TabsTrigger
                className="data-[state=active]:bg-primary data-[state=active]:text-white data-[state=active]:shadow-sm"
                value="My Engagements"
                disabled={!auth.email}
              >
                {t("my_engagements")}
              </TabsTrigger>
              <TabsTrigger
                value="Engagements For Activities"
                disabled={!auth.email}
                className="data-[state=active]:bg-primary data-[state=active]:text-white data-[state=active]:shadow-sm"
              >
                {t("engagements_for_my_activities")}
              </TabsTrigger>
            </TabsList>
            <TabsContent value="activities">
              <div className="">
                <ul className="flex flex-row gap-6 my-3">
                  <li>
                    <Button
                      onClick={() => selectPrescription(null, true)}
                      disabled={!auth.email}
                      className={auth.email ? "" : "disabledbutton"}
                    >
                      <FontAwesomeIcon icon={solid("plus")} />
                      &nbsp;&nbsp; {t("new_activity")}
                    </Button>
                  </li>
                  <li>
                    <Button
                      onClick={() =>
                        document.querySelector("#importPrescription").click()
                      }
                      disabled={!auth.email}
                      className={!auth.email ? "disabledbutton" : ""}
                      variant="outline"
                    >
                      <FontAwesomeIcon icon={solid("file-import")} />
                      &nbsp;&nbsp; {t("import_activity")}
                    </Button>
                  </li>
                  <li style={{ display: "none" }}>
                    <input
                      accept=".json"
                      type="file"
                      id="importPrescription"
                      onChange={handleImportPrescription}
                    />
                  </li>
                </ul>
              </div>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>{t("name")}</TableHead>
                    <TableHead className="text-center">
                      {t("versions")}
                    </TableHead>
                    <TableHead>&nbsp;</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {!!prescriptions
                    ? prescriptions.map((prescription, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell
                              className={
                                !prescription.valid ? "text-gray-300" : ""
                              }
                            >
                              {prescription.name}
                            </TableCell>
                            <TableCell className="text-center">
                              {prescription.versions}
                            </TableCell>
                            <TableCell className="equallySpaced">
                              <DropdownMenu>
                                <DropdownMenuTrigger className="bg-transparent ms-2">
                                  <div className="w-[30px]">
                                    <FontAwesomeIcon
                                      title={t("actions")}
                                      style={{ paddingTop: "4px" }}
                                      icon={solid("ellipsis-vertical")}
                                    />
                                  </div>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent>
                                  <DropdownMenuItem
                                    className="cursor-pointer"
                                    onClick={() =>
                                      selectPrescription(index, false)
                                    }
                                  >
                                    <div className="flex">
                                      <FontAwesomeIcon
                                        title={t("edit")}
                                        icon={solid("pen")}
                                        className="ml-2 mr-2 mt-1 w-[15px]"
                                      />
                                      <span>{t("edit")}</span>
                                    </div>
                                  </DropdownMenuItem>
                                  <DropdownMenuItem
                                    className="cursor-pointer"
                                    onClick={() =>
                                      viewPrescription(index, true)
                                    }
                                  >
                                    <div className="flex">
                                      <FontAwesomeIcon
                                        title={t("view")}
                                        icon={solid("eye")}
                                        className="ml-2 mr-2 mt-1 w-[15px]"
                                      />
                                      <span>{t("view")}</span>
                                    </div>
                                  </DropdownMenuItem>
                                  {auth.email ? (
                                    <DropdownMenuItem
                                      className="cursor-pointer"
                                      onClick={
                                        !auth.email
                                          ? null
                                          : () => renamePrescription(index)
                                      }
                                    >
                                      <div className="flex">
                                        <FontAwesomeIcon
                                          title={t("rename")}
                                          icon={solid("file-signature")}
                                          className="ml-2 mr-2 mt-1 w-[15px]"
                                        />
                                        <span>{t("rename")}</span>
                                      </div>
                                    </DropdownMenuItem>
                                  ) : (
                                    <></>
                                  )}
                                  {auth.email ? (
                                    <DropdownMenuItem
                                      className="cursor-pointer"
                                      onClick={
                                        !auth.email
                                          ? null
                                          : () => deletePrescription(index)
                                      }
                                    >
                                      {prescription.versions > 0 ? (
                                        <div className="flex">
                                          <FontAwesomeIcon
                                            title={t("archive")}
                                            icon={solid("box-archive")}
                                            className="ml-2 mr-2 mt-1 w-[15px]"
                                          />
                                          <span>{t("archive")}</span>
                                        </div>
                                      ) : (
                                        <div className="flex">
                                          <FontAwesomeIcon
                                            title={t("delete")}
                                            icon={solid("circle-xmark")}
                                            className="ml-2 mr-2 mt-1 w-[15px]"
                                          />
                                          <span>{t("delete")}</span>
                                        </div>
                                      )}
                                    </DropdownMenuItem>
                                  ) : (
                                    <></>
                                  )}
                                  {auth.email && !!prescription.valid ? (
                                    <>
                                      <DropdownMenuSeparator />
                                      <DropdownMenuItem
                                        className="cursor-pointer"
                                        onClick={
                                          !auth.email
                                            ? null
                                            : () =>
                                                copyPrescriptionAsExample(index)
                                        }
                                      >
                                        <div className="flex">
                                          <FontAwesomeIcon
                                            title={t(
                                              "copy_activity_as_example"
                                            )}
                                            icon={solid("upload")}
                                            className="ml-2 mr-2 mt-1 w-[15px]"
                                          />
                                          <span>
                                            {t("copy_activity_as_example")}
                                          </span>
                                        </div>
                                      </DropdownMenuItem>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {auth.email && !!prescription.valid ? (
                                    <DropdownMenuItem
                                      className="cursor-pointer"
                                      onClick={
                                        !auth.email || !prescription.valid
                                          ? null
                                          : () => sharePrescription(index)
                                      }
                                    >
                                      <div className="flex">
                                        <FontAwesomeIcon
                                          title={t("share")}
                                          icon={solid("share-nodes")}
                                          className="ml-2 mr-2 mt-1 w-[15px]"
                                        />
                                        <span>{t("share")}</span>
                                      </div>
                                    </DropdownMenuItem>
                                  ) : (
                                    <></>
                                  )}
                                  {auth.email && !!prescription.valid ? (
                                    <DropdownMenuItem
                                      className="cursor-pointer"
                                      onClick={
                                        !auth.email
                                          ? null
                                          : () => exportPrescription(index)
                                      }
                                    >
                                      <div className="flex">
                                        <FontAwesomeIcon
                                          title={t("export")}
                                          icon={solid("download")}
                                          className="ml-2 mr-2 mt-1 w-[15px]"
                                        />
                                        <span>{t("export")}</span>
                                      </div>
                                    </DropdownMenuItem>
                                  ) : (
                                    <></>
                                  )}
                                  {!!prescription.valid ? (
                                    <>
                                      <DropdownMenuSeparator />
                                      <DropdownMenuItem
                                        className="cursor-pointer"
                                        onClick={
                                          !prescription.valid
                                            ? null
                                            : () =>
                                                runPrescription(
                                                  prescriptions,
                                                  index
                                                )
                                        }
                                      >
                                        <div className="flex">
                                          <FontAwesomeIcon
                                            title={t("run")}
                                            icon={solid("play")}
                                            className="ml-2 mr-2 mt-1 w-[15px]"
                                          />
                                          <span>{t("run")}</span>
                                        </div>
                                      </DropdownMenuItem>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </DropdownMenuContent>
                              </DropdownMenu>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    : Array(Math.floor(Math.random() * 10 + 5))
                        .join(".")
                        .split(".")
                        .map((_, arrIdx) => {
                          return (
                            <TableRow key={arrIdx}>
                              <TableCell>
                                <Skeleton className="h-[20px] w-[400px] rounded-xl bg-gray-200" />
                              </TableCell>
                              <TableCell>
                                <Skeleton className="h-[20px] w-[40px] rounded-xl bg-gray-200" />
                              </TableCell>
                              <TableCell>
                                <Skeleton className="h-[20px] w-[40px] rounded-xl bg-gray-200" />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                </TableBody>
              </Table>
            </TabsContent>
            <TabsContent value="public">
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>{t("name")}</TableHead>
                    <TableHead>&nbsp;</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {!!publicPrescriptions
                    ? publicPrescriptions.map((prescription, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell
                              className={`${
                                (prescription.isWelcome ? "font-bold" : "") +
                                (!prescription.valid ? " text-gray-300" : "")
                              }`}
                            >
                              {prescription.name}
                            </TableCell>
                            <TableCell>
                              <DropdownMenu>
                                <DropdownMenuTrigger className="bg-transparent ms-2">
                                  <div className="w-[30px]">
                                    <FontAwesomeIcon
                                      title={t("actions")}
                                      style={{ paddingTop: "4px" }}
                                      icon={solid("ellipsis-vertical")}
                                    />
                                  </div>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent>
                                  <DropdownMenuItem
                                    className="cursor-pointer"
                                    onClick={() =>
                                      viewPrescription(index, false)
                                    }
                                  >
                                    <div className="flex">
                                      <FontAwesomeIcon
                                        title={t("view")}
                                        icon={solid("eye")}
                                        className="ml-2 mr-2 mt-1 w-[15px]"
                                      />
                                      <span>{t("view")}</span>
                                    </div>
                                  </DropdownMenuItem>
                                  <DropdownMenuItem
                                    className="cursor-pointer"
                                    onClick={() => copyPrescription(index)}
                                  >
                                    <div className="flex">
                                      <FontAwesomeIcon
                                        title={t("copy")}
                                        icon={solid("copy")}
                                        className="ml-2 mr-2 mt-1 w-[15px]"
                                      />
                                      <span>{t("copy")}</span>
                                    </div>
                                  </DropdownMenuItem>
                                  {!!prescription.valid ? (
                                    <>
                                      <DropdownMenuSeparator />
                                      <DropdownMenuItem
                                        className="cursor-pointer"
                                        onClick={() =>
                                          runOtherPrescription(index)
                                        }
                                      >
                                        <div className="flex">
                                          <FontAwesomeIcon
                                            title={t("run")}
                                            icon={solid("play")}
                                            className="ml-2 mr-2 mt-1 w-[15px]"
                                          />
                                          <span>{t("run")}</span>
                                        </div>
                                      </DropdownMenuItem>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </DropdownMenuContent>
                              </DropdownMenu>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    : Array(Math.floor(Math.random() * 10 + 5))
                        .join(".")
                        .split(".")
                        .map((_, arrIdx) => {
                          return (
                            <TableRow key={arrIdx}>
                              <TableCell>
                                <Skeleton className="h-[20px] w-[400px] rounded-xl bg-gray-200" />
                              </TableCell>
                              <TableCell>
                                <Skeleton className="h-[20px] w-[40px] rounded-xl bg-gray-200" />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                </TableBody>
              </Table>
            </TabsContent>
            <TabsContent value="My Engagements">
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>{t("activity_name")}</TableHead>
                    <TableHead>{t("expert")}</TableHead>
                    <TableHead>
                      {t("status")}{" "}
                      <select
                        className="filterselect"
                        value={filterown}
                        onChange={(e) => setFilterown(e.target.value)}
                      >
                        <option value="ALL">{t("ALL")}</option>
                        <option value="RUNNING">{t("RUNNING")}</option>
                      </select>
                    </TableHead>
                    <TableHead>{t("last_update")}</TableHead>
                    <TableHead>&nbsp;</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {!!filteredProcesses
                    ? filteredProcesses.map((process, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell>{process.prescriptionName}</TableCell>
                            <TableCell>
                              {process.fiExpert?.email}&nbsp;
                              {process.fiExpert &&
                              process.fiExpert.email !== auth.email ? (
                                <span
                                  className={
                                    "smallicon " +
                                    (process.onlineState === "offline"
                                      ? "offline"
                                      : process.onlineState === "online"
                                        ? "online"
                                        : "busy")
                                  }
                                >
                                  <FontAwesomeIcon
                                    title={t("onlinestate")}
                                    icon={solid("circle")}
                                  />
                                </span>
                              ) : (
                                ""
                              )}
                            </TableCell>
                            <TableCell>{t(process.status)}</TableCell>
                            <TableCell>
                              {showLocale(process.lastUpdated)}
                            </TableCell>
                            <TableCell>
                              {process.status === "RUNNING" ||
                              process.logCount > 0 ? (
                                <DropdownMenu>
                                  <DropdownMenuTrigger className="bg-transparent ms-2">
                                    <div className="w-[30px]">
                                      <FontAwesomeIcon
                                        title={t("actions")}
                                        style={{ paddingTop: "4px" }}
                                        icon={solid("ellipsis-vertical")}
                                      />
                                    </div>
                                  </DropdownMenuTrigger>
                                  <DropdownMenuContent>
                                    {process.status === "RUNNING" ? (
                                      <>
                                        {" "}
                                        <DropdownMenuItem
                                          className="cursor-pointer"
                                          onClick={() => continueProcess(index)}
                                        >
                                          <div className="flex">
                                            <FontAwesomeIcon
                                              title={t("continue")}
                                              icon={solid("forward")}
                                              className="ml-2 mr-2 mt-1 w-[15px]"
                                            />
                                            <span>{t("continue")}</span>
                                          </div>
                                        </DropdownMenuItem>
                                        <DropdownMenuItem
                                          className="cursor-pointer"
                                          onClick={() =>
                                            terminateProcess(index)
                                          }
                                        >
                                          <div className="flex">
                                            <FontAwesomeIcon
                                              title={t("terminate")}
                                              icon={solid("circle-xmark")}
                                              className="ml-2 mr-2 mt-1 w-[15px]"
                                            />
                                            <span>{t("terminate")}</span>
                                          </div>
                                        </DropdownMenuItem>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {process.logCount > 0 ? (
                                      <>
                                        {process.status === "RUNNING" ? (
                                          <DropdownMenuSeparator />
                                        ) : (
                                          <></>
                                        )}
                                        <DropdownMenuItem
                                          className="cursor-pointer"
                                          onClick={() => showUserLog(index)}
                                        >
                                          <div className="flex">
                                            <FontAwesomeIcon
                                              title={t("review")}
                                              icon={solid("file-lines")}
                                              className="ml-2 mr-2 mt-1 w-[15px]"
                                            />
                                            <span>{t("review")}</span>
                                          </div>
                                        </DropdownMenuItem>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </DropdownMenuContent>
                                </DropdownMenu>
                              ) : (
                                <></>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })
                    : Array(Math.floor(Math.random() * 10 + 5))
                        .join(".")
                        .split(".")
                        .map((_, arrIdx) => {
                          return (
                            <TableRow key={arrIdx}>
                              <TableCell>
                                <Skeleton className="h-[20px] w-[200px] rounded-xl bg-gray-200" />
                              </TableCell>
                              <TableCell>
                                <Skeleton className="h-[20px] w-[50px] rounded-xl bg-gray-200" />
                              </TableCell>
                              <TableCell>
                                <Skeleton className="h-[20px] w-[50px] rounded-xl bg-gray-200" />
                              </TableCell>
                              <TableCell>
                                <Skeleton className="h-[20px] w-[50px] rounded-xl bg-gray-200" />
                              </TableCell>
                              <TableCell>
                                <Skeleton className="h-[20px] w-[20px] rounded-xl bg-gray-200" />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                </TableBody>
              </Table>
            </TabsContent>
            <TabsContent value="Engagements For Activities">
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>{t("activity_name")}</TableHead>
                    <TableHead>{t("user")}</TableHead>
                    <TableHead>
                      {t("status")}
                      <select
                        className="filterselect"
                        value={filterexpert}
                        onChange={(e) => setFilterexpert(e.target.value)}
                      >
                        <option value="ALL">{t("ALL")}</option>
                        <option value="RUNNING">{t("RUNNING")}</option>
                      </select>
                    </TableHead>
                    <TableHead>{t("last_update")}</TableHead>
                    <TableHead>&nbsp;</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {!!filteredExpertProcesses
                    ? filteredExpertProcesses.map((process, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell>{process.prescriptionName}</TableCell>
                            <TableCell>
                              {process.fiInitiator?.email}&nbsp;
                              {process.fiInitiator &&
                              process.fiInitiator.email !== auth.email ? (
                                <span
                                  className={
                                    "smallicon " +
                                    (process.onlineState === "offline"
                                      ? "offline"
                                      : process.onlineState === "online"
                                        ? "online"
                                        : "busy")
                                  }
                                >
                                  <FontAwesomeIcon
                                    title={t("onlinestate")}
                                    icon={solid("circle")}
                                  />
                                </span>
                              ) : (
                                ""
                              )}
                            </TableCell>
                            <TableCell>{t(process.status)}</TableCell>
                            <TableCell>
                              {showLocale(process.lastUpdated)}
                            </TableCell>
                            <TableCell>
                              {!process.fiInitiator || process.logCount > 0 ? (
                                <Menubar>
                                  <MenubarMenu>
                                    <MenubarTrigger className="bg-transparent cursor-pointer w-[30px]">
                                      <FontAwesomeIcon
                                        title={t("actions")}
                                        style={{ paddingTop: "4px" }}
                                        icon={solid("ellipsis-vertical")}
                                      />
                                    </MenubarTrigger>
                                    <MenubarContent>
                                      {process.logCount > 0 ? (
                                        <MenubarItem
                                          className="cursor-pointer"
                                          onClick={() => showFullLog(index)}
                                        >
                                          <div className="flex">
                                            <FontAwesomeIcon
                                              title={t("review")}
                                              icon={solid("file-lines")}
                                              className="ml-2 mr-2 mt-1 w-[15px]"
                                            />
                                            <span>{t("review")}</span>
                                          </div>
                                        </MenubarItem>
                                      ) : (
                                        <></>
                                      )}
                                      {!process.fiInitiator ? (
                                        <MenubarSub>
                                          <MenubarSubTrigger>
                                            <div className="flex">
                                              <FontAwesomeIcon
                                                title={t("assign_user")}
                                                icon={solid("user")}
                                                className="ml-2 mr-2 mt-1 w-[15px]"
                                              />
                                              <span>{t("assign_user")}</span>
                                            </div>
                                          </MenubarSubTrigger>
                                          <MenubarSubContent>
                                            {allUsers.map((user, idx) => (
                                              <MenubarItem
                                                key={idx}
                                                onClick={() =>
                                                  selectUser(
                                                    process._id,
                                                    user.email
                                                  )
                                                }
                                              >
                                                {user.email}
                                              </MenubarItem>
                                            ))}
                                          </MenubarSubContent>
                                        </MenubarSub>
                                      ) : (
                                        <></>
                                      )}
                                    </MenubarContent>
                                  </MenubarMenu>
                                </Menubar>
                              ) : (
                                <></>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })
                    : Array(Math.floor(Math.random() * 10 + 5))
                        .join(".")
                        .split(".")
                        .map((_, arrIdx) => {
                          return (
                            <TableRow key={arrIdx}>
                              <TableCell>
                                <Skeleton className="h-[20px] w-[200px] rounded-xl bg-gray-200" />
                              </TableCell>
                              <TableCell>
                                <Skeleton className="h-[20px] w-[50px] rounded-xl bg-gray-200" />
                              </TableCell>
                              <TableCell>
                                <Skeleton className="h-[20px] w-[50px] rounded-xl bg-gray-200" />
                              </TableCell>
                              <TableCell>
                                <Skeleton className="h-[20px] w-[50px] rounded-xl bg-gray-200" />
                              </TableCell>
                              <TableCell>
                                <Skeleton className="h-[20px] w-[20px] rounded-xl bg-gray-200" />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                </TableBody>
              </Table>
            </TabsContent>
          </Tabs>
        </div>
      )}
      <Dialog open={showTerminate} onOpenChange={setShowTerminate}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{t("terminate_engagement")}</DialogTitle>
          </DialogHeader>
          <p>{t("question_terminate")}</p>
          <div className="flex flex-row gap-2 mt-2">
            <Button
              className="inline-block"
              onClick={async () => {
                Logger.debug()("Current engagement: " + currentProcess);
                let response = await retryFetch(
                  `${config.API_BASE}/api/processes/${currentProcess}`,
                  {
                    method: "DELETE",
                    credentials: "include",
                  }
                );
                let msg = await response.json();
                toast.success(t(msg.message));
                setCurrentProcess("");
                setShowTerminate(false);
                setListRefresh(true);
              }}
              data-id={"yes"}
              key={0}
              variant={`default`}
            >
              {t("yes")}
            </Button>
            <Button
              className="inline-block"
              onClick={() => {
                setShowTerminate(false);
              }}
              data-id={"no"}
              key={1}
              variant={`outline`}
            >
              {t("no")}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog open={showArchive} onOpenChange={setShowArchive}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{t("archive_activity")}</DialogTitle>
          </DialogHeader>
          <p>{t("question_archive_activity")}</p>
          <div className="flex flex-row gap-2 mt-2">
            <Button
              className="inline-block"
              onClick={async () => {
                let response = await retryFetch(
                  `${config.API_BASE}/api/prescriptions/${currentPrescription}`,
                  {
                    method: "DELETE",
                    credentials: "include",
                  }
                );
                let msg = await response.json();
                toast.success(t(msg.message));
                setCurrentPrescription("");
                setShowArchive(false);
                setListRefresh(true);
              }}
              data-id={"yes"}
              key={0}
              variant={`default`}
            >
              {t("yes")}
            </Button>
            <Button
              className="inline-block"
              onClick={() => {
                setShowArchive(false);
              }}
              data-id={"no"}
              key={1}
              variant={`outline`}
            >
              {t("no")}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog open={showDelete} onOpenChange={setShowDelete}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{t("delete_activity")}</DialogTitle>
          </DialogHeader>
          <p>{t("question_delete_activity")}</p>
          <div className="flex flex-row gap-2 mt-2">
            <Button
              className="inline-block"
              onClick={async () => {
                let response = await retryFetch(
                  `${config.API_BASE}/api/prescriptions/${currentPrescription}`,
                  {
                    method: "DELETE",
                    credentials: "include",
                  }
                );
                let msg = await response.json();
                toast.success(t(msg.message));
                setCurrentPrescription("");
                setShowDelete(false);
                setListRefresh(true);
              }}
              data-id={"yes"}
              key={0}
              variant={`default`}
            >
              {t("yes")}
            </Button>
            <Button
              className="inline-block"
              onClick={() => {
                setShowDelete(false);
              }}
              data-id={"no"}
              key={1}
              variant={`outline`}
            >
              {t("no")}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog open={showRename} onOpenChange={setShowRename}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{t("msg_rename_activity_to")}</DialogTitle>
          </DialogHeader>
          <label htmlFor="newName">{t("name")}</label>
          <input
            className="border-2 rounded pt-1 pl-2 pb-1"
            type="text"
            value={presNameNew}
            onChange={(e) => setPresNameNew(e.target.value)}
            id="newName"
          />
          <div className="flex flex-row gap-2 mt-2">
            <Button
              className="inline-block"
              onClick={async () => {
                await retryFetch(
                  `${config.API_BASE}/api/prescriptions/rename/${reference}/${presNameNew}`,
                  {
                    method: "PUT",
                    credentials: "include",
                  }
                );
                setReference(null);
                setShowRename(false);
                setPresNameNew("");
                setListRefresh(true);
              }}
              data-id={"ok"}
              key={0}
              variant={`default`}
              disabled={!presNameNew}
            >
              {t("ok")}
            </Button>
            <Button
              className="inline-block"
              onClick={() => {
                setShowRename(false);
                setPresNameNew("");
              }}
              data-id={"cancel"}
              key={1}
              variant={`outline`}
            >
              {t("cancel")}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      {background && <Preview />}
    </div>
  );
};

export default ActivitiesList;
