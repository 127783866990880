import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { GripVertical } from "lucide-react";
import { cn } from "../lib/utils";

function SortableItem(props) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props.id,
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    zIndex: isDragging ? 1000 : "auto",
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={cn(
        `flex items-center ${isDragging ? "bg-white shadow-md" : ""}`,
        props.className
      )}
    >
      <div
        {...attributes}
        {...listeners}
        className="cursor-grab p-1 m-1 hover:bg-slate-100"
      >
        <GripVertical size={20} />
      </div>
      <div className="flex-grow">{props.children}</div>
    </div>
  );
}

export default SortableItem;
