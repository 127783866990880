import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const LoginFromGoogle = () => {
  const { setAuth } = useAuth();

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    let accessToken = searchParams.get("accessToken");
    let email = searchParams.get("email");
    if (accessToken && email) {
      localStorage.setItem("email", email);
      localStorage.setItem("accessToken", accessToken);
      setAuth({ email, accessToken });

      let oldLocationState = sessionStorage.getItem("locationstate");
      if (oldLocationState) {
        let from = JSON.parse(oldLocationState).from.pathname;
        sessionStorage.removeItem("locationstate");
        navigate(from, { replace: true });
      } else {
        navigate("/activities");
      }
    } else {
      navigate("/login");
    }
  }, []);

  return <div>redirecting....</div>;
};

export default LoginFromGoogle;
